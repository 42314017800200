import { Container } from "reactstrap";
import w1 from "../../assets/images/webbing/web1.jpg";
import w2 from "../../assets/images/webbing/web2.png";
import w3 from "../../assets/images/webbing/web3.jpg";
import w4 from "../../assets/images/webbing/web4.png";
import w5 from "../../assets/images/webbing/web5.jpg";
import Footer from "../../components/Footer";

const Webbing = () => {
  return (
    <Container
      fluid
      className="p-0 mb-0 mx-0 min-h-screen mt-[4.5rem] flex flex-col items-center bg-gray-100"
    >
      {/* Section 1 */}
      <section
        className="w-full h-[20rem] bg-cover bg-no-repeat flex flex-col justify-center items-center text-white"
        style={{
          backgroundImage: `url(${w1})`,
        }}
      >
        <h1 className=" text-[1rem] lg:text-[2rem]">LEARN MORE</h1>
        <h1 className="font-bold text-[2rem] md:text-[3rem] lg:text-[4rem]">
          ABOUT WEBBING
        </h1>
        <div className="bg-white h-[0.2rem] w-[10rem] lg:w-[15rem] mt-3"></div>
      </section>

      {/* Section 2 */}
      <section className="w-full h-[17rem] flex flex-col justify-center items-center text-gray-800 p-2">
        <h1 className="font-bold text-[2rem] lg:text-[2.5rem]">
          WHAT IS WEBBING?
        </h1>
        <h1 className="text-center text-[0.85rem] max-w-3xl mt-3 leading-7">
          Webbing, also known as narrow fabric, is a strong woven textile,
          designed and manufactured in various forms for use in multiple
          industries. It is extremely versatile – often replacing steel wire,
          rope or chain in industrial, as well as non-industrial, applications.
        </h1>
      </section>

      {/* Section 3 */}
      <section className="w-[90%] text-gray-800 grid lg:grid-cols-3">
        <div className="lg:col-span-2 flex justify-center items-center">
          <img src={w2} className="w-[35rem] object-cover" />
        </div>
        <div className="flex flex-col justify-center w-[90%]">
          <h1 className="font-bold text-[1.7rem]">COMMON USES</h1>
          <h1 className="text-start text-[0.7rem] max-w-3xl mt-3 leading-5">
            Some common forms of webbing material used in everyday applications
            are seatbelts, load straps, and strapping for bags and canvas
            products. While its commercial applications include sporting goods,
            furniture, equestrian saddlery, marine and yachting equipment, pet
            leashes, footwear, and fitness apparel.
          </h1>
          <h1 className="text-start text-[0.7rem] max-w-3xl mt-3 leading-6">
            Industrial applications in the mining sector, automotive and
            transport sectors, rigging and other industrial manufacturing
            operations, tend to choose webbing over old-school materials due to
            its ease-of-use, low-risk and proven safety benefits.
          </h1>
        </div>
      </section>
      {/* Section 4 */}
      <section className="flex flex-col md:flex-row w-full h-fit">
        <div className="h-[30rem] md:h-[25rem] lg:h-[35rem] w-full md:w-[50%] lg:w-[50%] bg-[#2e303b] flex flex-col justify-center items-center p-2">
          <div className="text-white max-w-md">
            <h1 className="font-bold text-[1.7rem]">GENERAL TYPES</h1>
            <h1 className="text-start text-[0.7rem] max-w-3xl mt-3 leading-5">
              Generally, webbing takes the form of either flat or tubular
              fabric. With flat being more rigid and often stronger than its
              more flexible, although sometimes thicker, tubular counterpart.
              The type used is often dependant on the requirements of the final
              application.
            </h1>
            <h1 className="font-bold text-[1.2rem] my-4">
              PRODUCT APPLICATIONS
            </h1>
            <div className="grid grid-cols-2 text-[0.8rem] text-yellow-400 p-2">
              <div>
                <li>Binding Tape</li>
                <li>Coated Webbing</li>
                <li>Cure Wrap Tape</li>
                <li>Lifting & Recovery Webbing</li>
                <li> Safety Webbing</li>
              </div>
              <div>
                <li>Protective Sleeving</li>
                <li>Restraint Webbing</li>
                <li>Tie down Webbing</li>
                <li>Military Webbing</li>
                <li> Custom solutions</li>
              </div>
            </div>
          </div>
        </div>
        <div
          className="h-[20rem] md:h-[25rem] lg:h-[35rem] w-full md:w-[50%] lg:w-[50%] bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${w3})`,
          }}
        ></div>
      </section>
      {/* Section 5 */}
      <section className="w-full h-[23rem] flex flex-col justify-center items-center text-gray-800 p-2 bg-white">
        <h1 className="font-bold text-[2rem] lg:text-[2.5rem]">
          HOW IS WEBBING MADE?
        </h1>
        <h1 className="text-center text-[0.85rem] max-w-3xl mt-3 leading-7">
          Webbing products are fabrics which are woven from yarn. The yarn is
          chosen based on the application of the webbing and is either a natural
          or synthetic fibre.
        </h1>
        <h1 className="text-center text-[0.85rem] max-w-3xl mt-3 leading-7">
          Natural fibre yarn takes the form of cotton, whilst standard synthetic
          fibres include polyester, nylon and polypropylene. Exotic synthetic
          fibres would include such fibres as Kevlar ® – which is also used in
          adventure gear and bullet-proofing; Nomex ® – which is flame
          resistant; and Dyneema ® and Spectra ® – which are ultra-durable,
          strong and chemical resistant.
        </h1>
      </section>
      {/* Section 6 */}
      <section className="flex flex-col md:flex-row w-full h-fit">
        <div
          className="h-[20rem] md:h-[25rem] lg:h-[35rem] w-full md:w-[50%] lg:w-[50%] bg-cover bg-center bg-no-repeat"
          style={{
            backgroundImage: `url(${w4})`,
          }}
        ></div>
        <div className="h-[30rem] md:h-[25rem] lg:h-[35rem] w-full md:w-[50%] lg:w-[50%] bg-[#090d10] flex flex-col justify-center items-center p-2">
          <div className="text-white max-w-md p-3">
            <h1 className="font-bold text-[1.5rem]">THE WEAVING PROCESS</h1>
            <h1 className="text-start text-[0.8rem] max-w-3xl mt-3 leading-5">
              Weaving is a method of textile production in which two distinct
              sets of yarns are interlaced at right angles to form a fabric. The
              way the warp and weft interlace with each other is called the
              weave.
            </h1>
            <h1 className="text-start text-[0.8rem] max-w-3xl mt-3 leading-5">
              The warp is a term for the longitudinal threads and weft is the
              term used for lateral threads in the weave.
            </h1>
            <h1 className="text-start text-[0.8rem] max-w-3xl mt-3 leading-5">
              Weave types or patterns are chosen as part of the construction of
              the product to either meet certain technical requirements, or the
              aesthetics needed for the end-use application.
            </h1>
            <h1 className="text-start text-[0.8rem] max-w-3xl mt-3 leading-5">
              Such patterns and weave types include plain, pebble, twill,
              herringbone, satin and tubular.
            </h1>
            <h1 className="text-start text-[0.8rem] max-w-3xl mt-3 leading-5">
              At Webbing Products we specifically weave narrow fabrics – which
              is the manufacture of high-performance webbing that has a narrow
              width – up to and including 320mm and usually woven with a
              selvedge (self-finished) edge.
            </h1>
          </div>
        </div>
      </section>
      {/* Section 7 */}
      <section
        className="w-full h-[20rem] bg-cover bg-no-repeat bg-center flex flex-col justify-center items-center text-white"
        style={{
          backgroundImage: `url(${w5})`,
        }}
      >
        <h1 className="font-bold text-[1rem] md:text-[1rem] lg:text-[2rem] select-none">
          SPEAK TO A WEBBING EXPERT TODAY
        </h1>
      </section>
      <Footer />
    </Container>
  );
};

export default Webbing;

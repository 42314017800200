import React, { useState } from "react";
import logo from "../assets/images/logo/logo.png";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

function NavBar() {
  const [isOpen, setIsOpen] = useState(false);
  const [productsDropDown, setProductsDropDown] = useState(false);
  const [aboutDropDown, setAboutDropDown] = useState(false);
  const [serviceDropDown, setServiceDropDown] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  return (
    <div>
      <Navbar
        expand="lg"
        fixed="top"
        className="bg-white min-h-[4.5rem] backdrop-blur-md"
      >
        <NavbarBrand
          href="/"
          className="h-[3rem] w-[240px] object-contain flex justify-center items-center mr-10"
        >
          <img alt="Fineweb" src={logo} className="self-center" />
        </NavbarBrand>
        <NavbarToggler onClick={toggle} />
        <Collapse isOpen={isOpen} navbar>
          <Nav
            className="me-auto lg:w-[83%] xl:w-[88%] text-sm justify-evenly text-[1rem] font-bold"
            navbar
          >
            <NavItem>
              <NavLink
                href="/"
                className="hover:text-[#a18322] md:hover:scale-105 duration-[300ms] transition-transform"
              >
                Home
              </NavLink>
            </NavItem>
            <UncontrolledDropdown
              nav
              inNavbar
              toggle={() => setProductsDropDown(!productsDropDown)}
              onMouseEnter={() => setProductsDropDown(true)}
              onMouseLeave={() => setProductsDropDown(false)}
              isOpen={productsDropDown}
            >
              <DropdownToggle
                nav
                caret
                className="hover:text-[#a18322] md:hover:scale-105 duration-[300ms] transition-transform"
              >
                Products
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  href="/products/Nawar Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Nawar Webbing
                </DropdownItem>
                <DropdownItem
                  href="/products/Fine Special Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Fine Special Webbing
                </DropdownItem>
                <DropdownItem
                  href="/products/Multi Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Multi Webbing
                </DropdownItem>
                <DropdownItem
                  href="/products/Printed Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Printed Webbing
                </DropdownItem>
                <DropdownItem
                  href="/products/Fancy Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Fancy Webbing
                </DropdownItem>
                <DropdownItem
                  href="/products/Sports Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Sports Webbing
                </DropdownItem>
                <DropdownItem
                  href="/products/Maghzi Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Maghzi Webbing
                </DropdownItem>
                <DropdownItem
                  href="/products/Special Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  Special Webbing
                </DropdownItem>

                <DropdownItem
                  href="/products/H.L.W Webbing"
                  className="hover:text-[#a18322] text-sm"
                >
                  H.L.W Webbing
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown
              nav
              inNavbar
              toggle={() => setAboutDropDown(!aboutDropDown)}
              onMouseEnter={() => setAboutDropDown(true)}
              onMouseLeave={() => setAboutDropDown(false)}
              isOpen={aboutDropDown}
            >
              <DropdownToggle
                nav
                caret
                className="hover:text-[#a18322] md:hover:scale-105 duration-[300ms] transition-transform"
              >
                About Us
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  href="/about-us/introduction"
                  className="hover:text-[#a18322] text-sm"
                >
                  Our Introduction
                </DropdownItem>
                <DropdownItem
                  href="/about-us/OurMission"
                  className="hover:text-[#a18322] text-sm"
                >
                  Our Mission
                </DropdownItem>
                <DropdownItem
                  className="hover:text-[#a18322] text-sm"
                  href="/about-us/OurVision"
                >
                  Our Vision
                </DropdownItem>
                <DropdownItem
                  className="hover:text-[#a18322] text-sm"
                  href="/about-us/CoreValues"
                >
                  Core Values
                </DropdownItem>
                <DropdownItem
                  href="/about-us/messageFromCEO"
                  className="hover:text-[#a18322] text-sm"
                >
                  CEO Message
                </DropdownItem>
                <DropdownItem
                  className="hover:text-[#a18322] text-sm"
                  href="/about-us/OurStandards"
                >
                  Our Standards
                </DropdownItem>
                <DropdownItem
                  className="hover:text-[#a18322] text-sm"
                  href="/about-us/QulityAssurance"
                >
                  Qulity Assurance
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown
              nav
              inNavbar
              toggle={() => setServiceDropDown(!serviceDropDown)}
              onMouseEnter={() => setServiceDropDown(true)}
              onMouseLeave={() => setServiceDropDown(false)}
              isOpen={serviceDropDown}
            >
              <DropdownToggle
                nav
                caret
                className="hover:text-[#a18322] md:hover:scale-105 duration-[300ms] transition-transform"
              >
                Services
              </DropdownToggle>
              <DropdownMenu end>
                <DropdownItem
                  href="/sample"
                  className="hover:text-[#a18322] text-sm"
                >
                  Sample Request
                </DropdownItem>
                <DropdownItem
                  href="/offer"
                  className="hover:text-[#a18322] text-sm"
                >
                  Offer Request
                </DropdownItem>
                <DropdownItem
                  className="hover:text-[#a18322] text-sm"
                  href="/catalogue"
                >
                  Catalogue Request
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <NavItem>
              <NavLink
                href="/contact"
                className="hover:text-[#a18322] md:hover:scale-105 duration-[300ms] transition-transform"
              >
                Contact
              </NavLink>
            </NavItem>
          </Nav>
          <NavLink
            className="text-[0.7rem] hover:text-[#a18322] md:hover:scale-105 duration-[300ms] transition-transform"
            href="/webbing"
          >
            More about Webbing?
          </NavLink>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default NavBar;

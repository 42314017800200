import React, { useState } from "react";
import { Container } from "reactstrap";
import Spinner from "../../components/Spinner";
import emailjs from "@emailjs/browser";
import Footer from "../../components/Footer";
import header from "../../assets/images/contactus/header.jpg";

const ContactUs = () => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true);
    emailjs
      .send(
        "service_mca44pn",
        "template_4bymhr7",
        {
          name: formData.name,
          email: formData.email,
          phone: formData.phone,
          message: formData.message,
        },
        "riT4aMzR3aTtGzRuq"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      )
      .finally(() => {
        setLoader(false);
        setFormData({
          name: "",
          email: "",
          contact: "",
          message: "",
        });
      });
  };

  return (
    <>
      <Container
        fluid
        className="min-h-screen w-full m-0 px-0 pb-0 pt-[4.5rem] flex flex-col"
      >
        <section
          className="w-full h-[20rem] bg-cover bg-no-repeat bg-center flex flex-col justify-center items-center text-white"
          style={{
            backgroundImage: `url(${header})`,
          }}
        >
          <h1 className="font-bold text-[2rem] md:text-[3rem] lg:text-[4rem]">
            Contact Us
          </h1>
          <div className="bg-white h-[0.2rem] w-[10rem] lg:w-[15rem] mt-3"></div>
        </section>
        <section className="h-full w-full flex justify-center items-center px-2 mt-4">
          <div className="w-full md:w-[80%] xl:w-[40%] overflow-scroll rounded border p-2 bg-gray-100 items-center">
            {/* Form Div */}
            <div className="flex flex-col justify-center items-center">
              {loader ? (
                <Spinner />
              ) : (
                <form
                  className="w-full items-center flex flex-col"
                  onSubmit={handleSubmit}
                >
                  <div className="grid grid-cols-2 gap-2 mb-2 w-full">
                    <div>
                      <label
                        htmlFor="name"
                        className="block text-[0.7rem] font-medium text-gray-600"
                      >
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>
                    <div>
                      <label
                        htmlFor="phone"
                        className="block text-[0.7rem] font-medium text-gray-600"
                      >
                        Phone
                      </label>
                      <input
                        type="tel"
                        id="phone"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        required
                        className="mt-1 p-2 w-full border rounded-md"
                      />
                    </div>
                  </div>
                  <div className="mb-2 w-full">
                    <label
                      htmlFor="email"
                      className="block text-[0.7rem] font-medium text-gray-600"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      required
                      className="mt-1 p-2 w-full border rounded-md"
                    />
                  </div>

                  <div className="mb-2 w-full">
                    <label
                      className="block text-[0.7rem] font-medium text-gray-600"
                      htmlFor="message"
                    >
                      Message
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="mt-1 p-2 w-full border rounded-md"
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                  <div className="w-full justify-center items-center flex p-2 bg-[#aa9868]/40">
                    <button
                      type="submit"
                      className="w-fit text-black border py-2 px-4 rounded-md
                     focus:outline-none hover:bg-[#a18322]/30"
                    >
                      Submit
                    </button>
                  </div>
                </form>
              )}
            </div>
          </div>
        </section>
      </Container>
      <Footer />
    </>
  );
};

export default ContactUs;

import { useEffect } from "react";
import { Container } from "reactstrap";
import { useParams } from "react-router-dom";
import { PRODUCTS } from "../../assets/constants/data";
import ProductCard from "../../components/ProductCard";
import Footer from "../../components/Footer";
import header from "../../assets/images/products/header.jpg";

const Products = () => {
  const { id = "Nawar Webbing" } = useParams();
  useEffect(() => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [id]);

  return (
    <Container
      fluid
      className="w-full min-h-screen m-0 flex flex-col pt-[4.5rem] px-0 pb-0"
    >
      <section
        className="w-full h-[20rem] bg-cover bg-no-repeat flex flex-col justify-center items-center text-white"
        style={{
          backgroundImage: `url(${header})`,
        }}
      >
        <h1 className="font-bold text-[2rem] md:text-[3rem] lg:text-[4rem]">
          Products
        </h1>
        <div className="bg-white h-[0.2rem] w-[10rem] lg:w-[15rem] mt-3"></div>
      </section>
      <section
        id="products-map"
        className="w-fit mx-auto grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 items-center justify-center gap-14 mt-10 mb-5"
      >
        {PRODUCTS.map((item, key) => (
          <div id={item.name} key={key}>
            <ProductCard
              main
              showDetails={item.showDetails}
              colorRange={item.colorRange}
              quality={item.quality}
              name={item.name}
              productImg={item.productImg}
              key={key}
              sizes={item.sizes}
              custom={item.custom}
            />
          </div>
        ))}
      </section>
      <Footer />
    </Container>
  );
};

export default Products;

import React from "react";
import { Container } from "reactstrap";
import Slider from "../../components/slider";
import ProductCard from "../../components/ProductCard";
import { PRODUCTS } from "../../assets/constants/data";
import Footer from "../../components/Footer";
import intro from "../../assets/images/home/intro.jpg";
import services from "../../assets/images/home/services.jpg";

const WhyCard = (props) => {
  return (
    <div className="rounded-md border border-neutral-800 bg-neutral-900/50 p-8 text-center shadow">
      {/* <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md border bg-gradient-to-r from-purple-500 to-blue-500"> */}
      <div className="button-text mx-auto flex h-12 w-12 items-center justify-center rounded-md ">
        {props.svg}
      </div>
      <h3 className="mt-6 text-gray-300 font-bold">{props.title}</h3>
      <p className="my-4 mb-0 font-normal leading-relaxed tracking-wide text-gray-300">
        {props.para}
      </p>
    </div>
  );
};

const Home = () => {
  return (
    <Container
      fluid
      className="w-full items-center mb-0 mx-0 p-0 flex flex-col bg-white mt-[4.5rem]"
    >
      <section className="w-screen">
        <Slider />
      </section>

      <section className="w-full p-4 flex justify-center items-center shadow-md">
        {/* Text Side */}
        <div className="grid lg:grid-cols-2 gap-7 text-center my-4 w-[90%] lg:w-[80%] xl:w-[80%]">
          <div className="flex flex-col justify-center items-center">
            <p className="text-gray-500 text-justify text-2xl mb-1 font-bold">
              Introduction
            </p>
            <p className="text-gray-500 text-justify text-sm">
              Established in 1986, The company Fine Web stands as a
              distinguished narrow fabric manufacturer based in Lahore,
              Pakistan. Renowned for its unwavering commitment to excellence,
              Fine Web specializes in high-quality products. The Company excels
              in customization to meet clients' specific requirements and
              fostering relationships. We have state-of-the-art facilities
              equipped with cutting-edge machines and computerized jacquard
              needle looms, reflecting our dedication to innovation and
              continuous improvement. Serving diverse market segments, we supply
              webbing as a crucial component for various manufacturers. With an
              extensive product range, customization capabilities and
              solutions-oriented approach, Fine Web is the premium choice for
              webbing supplies in the market and industry. Whether standard
              materials or highly specialized designs are needed, we confidently
              fulfill requirements in a cost-effective manner. Our skilled
              workforce and customer-centric approach underscore our commitment
              to delivering solutions that surpass client expectations. As the
              company adapts to the evolving textile landscape, our steadfast
              dedication to unparalleled quality and service remains unchanged.
            </p>
          </div>
          {/* Media Side */}
          <div className="h-full w-full flex overflow-hidden justify-center items-center">
            <img
              alt="Introduction"
              className="w-full h-[23rem] xl:h-[20rem] overflow-hidden object-cover rounded-md flex justify-center items-center"
              src={intro}
            />
          </div>
        </div>
      </section>
      {/* Our Services */}
      <section className="w-full p-4 flex justify-center items-center bg-gray-200/30">
        <div className="grid lg:grid-cols-2 gap-7 text-center my-4 w-[90%] lg:w-[80%] xl:w-[80%]">
          {/* Media Side */}
          <div className="h-full w-full flex overflow-hidden justify-center items-center">
            <img
              alt="Services"
              className="w-full h-[23rem] xl:h-[20rem] overflow-hidden object-cover rounded-md flex justify-center items-center"
              src={services}
            />
          </div>
          {/* Text Side */}
          <div className="flex flex-col justify-center items-center">
            <p className="text-gray-500 text-justify text-2xl mb-1 font-bold">
              Services
            </p>
            <p className="text-gray-500 text-justify text-sm">
              As a distinguished family-run business, Fine Web takes great pride
              in developing strong relationships with our valued customers.
              Together, we set out to create woven products that are precisely
              to your required specifications. Fine Web, which specializes in
              producing a wide variety of narrow fabrics, is skilled at creating
              custom solutions that incorporate a wide range of colours, complex
              patterns, and materials. We remain steadfast in our dedication to
              personalization, making sure that our products and services
              perfectly meet the distinct needs of each and every one of our
              valued clients.
            </p>
          </div>
        </div>
      </section>
      {/* Our Products */}
      <section className="w-full p-4 flex flex-col justify-center items-center">
        <h1 className="text-gray-500 text-justify text-[2rem] mb-1 font-bold">
          Our Products
        </h1>
        <div
          id="Products"
          className="w-fit mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:hidden items-center justify-center gap-4 mt-10 mb-5"
        >
          {PRODUCTS.map((item, key) => (
            <ProductCard
              name={item.name}
              productImg={item.productImg}
              key={key}
            />
          ))}
        </div>

        {/* Larger Display */}
        <div
          id="Products"
          className="w-fit mx-auto hidden xl:grid xl:grid-cols-5 items-center justify-center gap-4 mt-10 mb-5"
        >
          {PRODUCTS.slice(0, 5).map((item, key) => (
            <ProductCard
              name={item.name}
              productImg={item.productImg}
              key={key}
            />
          ))}
        </div>
        <div
          id="Products"
          className="w-fit mx-auto hidden xl:grid xl:grid-cols-4 items-center justify-center gap-4 mt-10 mb-5"
        >
          {PRODUCTS.slice(5, 9).map((item, key) => (
            <ProductCard
              name={item.name}
              productImg={item.productImg}
              key={key}
            />
          ))}
        </div>
      </section>
      {/* Why Us */}
      <section className="w-full bg-black/20">
        <div
          id="features"
          className="relative block px-6 py-10 md:py-20 md:px-10 "
        >
          <div className="relative mx-auto max-w-5xl text-center">
            <span className="text-gray-600 my-3 flex items-center justify-center font-medium uppercase tracking-wider">
              Why choose us
            </span>
            {/* <h2 className="block w-full bg-gradient-to-b from-black/50 to-[#aa9868] bg-clip-text font-bold text-transparent text-3xl sm:text-4xl"> */}
            <h2 className="block w-full font-bold text-[#aa9868] text-3xl sm:text-4xl">
              Choose Excellence, Embrace Quality
            </h2>
            <p className="mx-auto my-4 w-full max-w-xl bg-transparent text-center font-medium leading-relaxed tracking-wide text-gray-600">
              Elevate your projects with our cutting-edge webbing textiles.
              Unparalleled quality, innovation, and tailored solutions make us
              your ideal partner. Choose reliability and set your endeavors
              apart with us.
            </p>
          </div>

          <div className="relative mx-auto max-w-7xl z-10 grid grid-cols-1 gap-10 pt-14 sm:grid-cols-2 lg:grid-cols-3 ">
            {/* Feature 1 */}

            <WhyCard
              svg={
                <svg
                  className="h-full w-full"
                  xmlns="http://www.w3.org/2000/svg"
                  data-name="1"
                  viewBox="0 0 128 128"
                  id="quality"
                >
                  <path
                    fill="#ff8a65"
                    d="m90.69 74.16 14.7 45.12-16.48-5.16L78.65 128 65.04 86.23M36.17 75.14l-13.56 44.14 16.48-5.16L49.35 128l12.72-41.56"
                  ></path>
                  <path
                    fill="#ffe082"
                    d="m68.07 3.2 3.76-2.13a8.26 8.26 0 0 1 11.38 3.34l2 3.83a8.26 8.26 0 0 0 6.84 4.4l4.32.24a8.26 8.26 0 0 1 7.76 9l-.37 4.31a8.26 8.26 0 0 0 3.38 7.4l3.5 2.54a8.26 8.26 0 0 1 1.69 11.73l-2.64 3.42a8.26 8.26 0 0 0-1.16 8.05l1.57 4a8.26 8.26 0 0 1-4.92 10.78l-4.07 1.45a8.26 8.26 0 0 0-5.33 6.15l-.85 4.24a8.26 8.26 0 0 1-10 6.41l-4.21-1a8.26 8.26 0 0 0-7.81 2.29l-3 3.1a8.26 8.26 0 0 1-11.85 0l-3-3.1a8.26 8.26 0 0 0-7.81-2.29l-4.21 1a8.26 8.26 0 0 1-10-6.41l-.85-4.24a8.26 8.26 0 0 0-5.33-6.15l-4.07-1.45a8.26 8.26 0 0 1-4.92-10.78l1.57-4a8.26 8.26 0 0 0-1.16-8.05l-2.64-3.42a8.26 8.26 0 0 1 1.69-11.73l3.5-2.54a8.26 8.26 0 0 0 3.38-7.4l-.37-4.31a8.26 8.26 0 0 1 7.76-9l4.32-.24a8.26 8.26 0 0 0 6.84-4.4l2-3.83a8.26 8.26 0 0 1 11.41-3.34l3.76 2.13a8.26 8.26 0 0 0 8.14 0Z"
                  ></path>
                  <circle cx="64" cy="49.64" r="20.55" fill="#fff"></circle>
                  <path
                    fill="#ffb74d"
                    d="M64 29.09a20.55 20.55 0 1 1-20.55 20.55A20.55 20.55 0 0 1 64 29.09m0-8a28.55 28.55 0 1 0 28.55 28.55A28.58 28.58 0 0 0 64 21.09Z"
                  ></path>
                </svg>
              }
              title="Quality"
              para="Fine Web strives to continuously improve its products to better
                satisfy the needs of its customers and to deliver defect-free
                products on time, every time."
            />
            <WhyCard
              svg={
                <svg
                  className="h-full w-full"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 882.672 1010"
                  id="quick"
                >
                  <path
                    fill="#545454"
                    d="M882.672 568.664c0 243.734-197.579 441.336-441.336 441.336S0 812.398 0 568.664C0 348.578 161.04 166.22 371.71 132.796V90.809h-32.75c-14.37 0-26.16-11.768-26.16-26.137V26.115C312.8 11.747 324.59 0 338.96 0h192.267c14.347 0 26.093 11.747 26.093 26.115v38.557c0 14.369-11.746 26.137-26.093 26.137h-32.773v40.283c216.745 28.02 384.218 213.181 384.218 437.572zm-114.3-.023c0-180.634-146.404-327.036-327.036-327.036S114.302 388.007 114.302 568.641c0 180.61 146.403 327.035 327.034 327.035S768.372 749.25 768.372 568.64z"
                  ></path>
                  <path
                    fill="#DAB466"
                    d="M406.883 323.852c0-17.44 14.1-31.585 31.54-31.585 17.417 0 31.584 14.145 31.584 31.585 0 17.417-14.167 31.54-31.584 31.54-17.44 0-31.54-14.123-31.54-31.54zm237.994 21.766L472.944 517.575c-9.214-5.717-19.974-9.1-31.608-9.1-33.22 0-60.166 26.921-60.166 60.166 0 33.243 26.945 60.188 60.166 60.188 33.2 0 60.165-26.945 60.165-60.188 0-12.308-3.854-23.605-10.22-33.155l171.8-171.778-18.204-18.09z"
                  ></path>
                </svg>
              }
              title="Quick Turnaround"
              para=" We understand the importance of timely response to the customer
              queries providing them with effective solutions and honoring our
              commitments."
            />
            <WhyCard
              svg={
                <svg
                  className="h-full w-full"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 64 64"
                  id="customer-service"
                >
                  <g fill="#3EBBDD">
                    <path d="M7 32H5.97C3.781 32 2 33.781 2 36.03A3.975 3.975 0 0 0 5.97 40H7a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1zM58.03 32H57a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h1.03c2.189 0 3.97-1.781 3.97-4.03A3.975 3.975 0 0 0 58.03 32z"></path>
                  </g>
                  <path
                    fill="#E6E6E6"
                    d="m24.949 8.217.05-.018A19.756 19.756 0 0 1 31.8 7h.4C43.135 7 52 15.865 52 26.8V31h4v-4.24C56 13.638 45.362 3 32.24 3h-.48C18.638 3 8 13.638 8 26.76V31h4v-4.2c0-6.095 2.754-11.547 7.086-15.179l1.95-1.442a19.609 19.609 0 0 1 1.848-1.062l2.065-.9zM36.18 60H36v-2h.18C45.455 58 53 50.454 53 41.18V41h2v.18C55 51.558 46.558 60 36.18 60z"
                  ></path>
                  <path
                    fill="#4D4D4D"
                    d="M13 30H7a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V31a1 1 0 0 0-1-1zM57 30h-6a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V31a1 1 0 0 0-1-1z"
                  ></path>
                  <path
                    fill="#3EBBDD"
                    d="M34.02 56h-4.04C28.337 56 27 57.337 27 59.02A2.983 2.983 0 0 0 29.98 62h4.04c1.643 0 2.98-1.337 2.98-3.02A2.984 2.984 0 0 0 34.02 56zM16 35h4v2h-4zM44 35h4v2h-4zM22 34h2v4h-2zM26 31h2v10h-2zM31 29h2v14h-2zM36 31h2v10h-2zM40 34h2v4h-2z"
                  ></path>
                </svg>
              }
              title="Reliability and Customer Service"
              para="Fine Web takes prides in providing reliable and personalized
              service. We believe each customer has unique needs and we meet
              or exceed their requirements, not only in product quality but in
              everything that will create a win-win collaboration."
            />
          </div>

          {/* Background Gradient Lines */}
          <div
            className="absolute bottom-0 left-0 z-0 h-1/3 w-full border-b"
            style={{
              backgroundImage:
                "linear-gradient(to right top, #aa9868 0%, transparent 50%, transparent 100%)",
              // borderColor: "rgba(92, 79, 240, 0.2)",
            }}
          ></div>
          <div
            className="absolute bottom-0 right-0 z-0 h-1/3 w-full"
            style={{
              backgroundImage:
                "linear-gradient(to left top, #665b3e 0%, transparent 50%, transparent 100%)",
              // borderColor: "rgba(92, 79, 240, 0.2)",
            }}
          ></div>
        </div>
      </section>
      <Footer />
    </Container>
  );
};

export default Home;

import React from "react";
import "./FineWeb.css";
import { Container } from "reactstrap";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "../home";
import NavBar from "../../components/NavBar";
import AboutUs from "../about";
import Products from "../products";
import Webbing from "../webbing";
import ContactUs from "../contact";
import SampleRequest from "../services/SampleRequest";
import OfferRequest from "../services/OfferRequest";
import CatalogueRequest from "../services/CatalogueRequest";

const FineWeb = () => {
  return (
    <Container fluid className="p-0 m-0 bg-white text-black">
      <Router>
        <NavBar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us/:id?" element={<AboutUs />} />
          <Route path="/products/:id?" element={<Products />} />
          <Route path="/webbing" element={<Webbing />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/sample" element={<SampleRequest />} />
          <Route path="/offer" element={<OfferRequest />} />
          <Route path="/catalogue" element={<CatalogueRequest />} />
        </Routes>
      </Router>
    </Container>
  );
};

export default FineWeb;

import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import title1 from "../assets/images/home/title1.jpg";
import title2 from "../assets/images/home/title2.jpg";
import fw from "../assets/images/logo/FW.png";
const Slider = () => {
  const BACKGROUNDS = [
    {
      img: title1,
    },
    {
      img: title2,
    },
  ];

  const carouselOptions = {
    responsive: {
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 1,
        slidesToSlide: 1,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 1,
        slidesToSlide: 1,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
        slidesToSlide: 1,
      },
    },
  };

  return (
    <Carousel
      {...carouselOptions}
      infinite={true}
      autoPlay={true}
      // autoPlaySpeed={1000}
    >
      {BACKGROUNDS.map((item, index) => (
        <div
          key={index}
          className="relative h-[calc(70vh-4.5rem)] w-full bg-cover bg-no-repeat bg-center flex flex-col justify-center items-center"
          style={{ backgroundImage: `url(${item.img})` }}
        >
          <div className=" w-fit flex flex-col">
            <div className="flex backdrop-blur-md bg-black/10 mb-1 items-center justify-center px-2 py-1">
              <img
                src={fw}
                alt="Fineweb"
                className="object-contain w-[6rem]  md:w-[12rem] lg:w-[14rem] m-0 p-0 "
              />
              <h1 className="font-bold text-center text-[3rem] lg:text-[6rem] text-white  ">
                Fine<span className="text-[#aa9868] font-bold">Web</span>
              </h1>
            </div>

            <h1 className="font-bold text-center text-[0.8rem] lg:text-[1.5rem] text-white backdrop-blur-md bg-white/10 p-2">
              Manufacturer of all kinds of web.
            </h1>
          </div>
          {/* <div className="absolute bottom-6 lg:bottom-10 left-0 w-[60%] sm:w-[30%] md:w-[30%] lg:w-[30%] flex flex-col">
            <div className="flex backdrop-blur-md bg-black/50 my-1 items-center justify-center rounded-tr-full">
              <img
                src={fw}
                alt="Fineweb"
                className="object-contain h-[2rem] w-[2rem] lg:h-[4rem] lg:w-[4rem]"
              />
              <h1 className="font-bold text-center text-[1rem] lg:text-[1.7rem] text-white  ">
                Fine<span className="text-[#aa9868] font-bold">Web</span>
              </h1>
            </div>

            <h1 className="font-bold text-center text-[0.6rem] lg:text-[1rem] text-white backdrop-blur-md bg-black/50 p-2">
              Manufacturer of all kinds of web.
            </h1>
          </div> */}
        </div>
      ))}
    </Carousel>
  );
};

export default Slider;

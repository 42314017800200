import React, { useState } from "react";
import { Container } from "reactstrap";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";
import emailjs from "@emailjs/browser";

const CatalogueRequest = () => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    companyName: "",
    name: "",
    street: "",
    house: "",
    houseSuffix: "",
    zipCode: "",
    city: "",
    country: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true);
    emailjs
      .send(
        "service_mca44pn",
        "template_bfd9zxl",
        {
          type: "Catalogue Request Form",
          productCategory: "N/A",
          productDescription: "N/A",
          widthReceive: "N/A",
          amount: 0,
          companyName: formData.companyName,
          name: formData.name,
          street: formData.street,
          house: formData.house,
          houseSuffix: formData.houseSuffix,
          zipCode: formData.zipCode,
          city: formData.city,
          country: formData.country,
          email: formData.email,
          contact: formData.contact,
          message: formData.message,
        },
        "riT4aMzR3aTtGzRuq"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      )
      .finally(() => {
        setLoader(false);
        setFormData({
          companyName: "",
          name: "",
          street: "",
          house: "",
          houseSuffix: "",
          zipCode: "",
          city: "",
          country: "",
          email: "",
          contact: "",
          message: "",
        });
      });
  };

  return (
    <Container
      fluid
      className="px-1 pt-3 mb-0 mx-0 h-fit md:min-h-screen lg:min-h-screen w-full justify-center items-center flex flex-col mt-[4.5rem]"
    >
      <section className="w-full flex flex-col justify-center items-center p-3 min-h-[15rem] bg-[#aa9868]/40 mb-4">
        <h1 className="max-w-2xl mt-2 mb-4 p-2 text-md text-justify">
          <span className="font-bold">RECEIVE CATALOGUE</span>
          <br className="mb-3" />
          As part of our core commitment to providing textile solutions
          specifically for your product/project, we have developed a catalogue
          for our customers. These catalogues provide a detailed glimpse into
          the full spectrum of textile solutions we supply from stock.
          <br className="mb-3" />
          Are you interested to see what we could offer? Then you can ask for
          our catalogue via the request form. Simply fill in the request form
          below and we will send you the catalogue as soon as possible.
        </h1>
      </section>

      {loader ? (
        <div className="h-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <form
          className="max-w-lg min-w-[80%] lg:min-w-[45%] border p-4 mb-4"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-2 gap-2 my-2 w-full">
            <div>
              <label
                htmlFor="companyName"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Company Name
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your Company Name"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="name"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your Full Name"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
          </div>
          {/* ----------------- */}
          <div className="grid md:grid-cols-3 gap-2 my-2 w-full">
            <div>
              <label
                htmlFor="street"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Street
              </label>
              <input
                type="text"
                id="street"
                name="street"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Street"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="house"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                House No.
              </label>
              <input
                type="text"
                id="house"
                name="house"
                value={formData.phone}
                onChange={handleChange}
                placeholder="House #"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="houseSuffix"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                House Number Suffix
              </label>
              <input
                type="text"
                id="houseSuffix"
                name="houseSuffix"
                value={formData.phone}
                onChange={handleChange}
                placeholder="House number suffix"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
          </div>
          {/* ----------------- */}
          <div className="grid md:grid-cols-3 gap-2 my-2 w-full">
            <div>
              <label
                htmlFor="zipCode"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Zip Code
              </label>
              <input
                type="text"
                id="zipCode"
                name="zipCode"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Zip Code"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="city"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                City
              </label>
              <input
                type="text"
                id="city"
                name="city"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your City"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="country"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Country
              </label>
              <input
                type="text"
                id="country"
                name="country"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your Country"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
          </div>
          {/* ----------------- */}
          <div className="grid grid-cols-2 gap-2 mb-2 w-full">
            <div>
              <label
                htmlFor="email"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your Email"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="contact"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Contact
              </label>
              <input
                type="tel"
                id="contact"
                name="contact"
                value={formData.phone}
                onChange={handleChange}
                placeholder="Your Contact Number"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
          </div>
          {/* ----------------- */}
          <label
            htmlFor="message"
            className="block text-[0.7rem] font-medium text-gray-600 mt-2"
          >
            Additional Information
          </label>
          <textarea
            type="text"
            id="message"
            name="message"
            placeholder="Your message here..."
            value={formData.phone}
            onChange={handleChange}
            required
            className="mt-1 p-1 w-full border text-[0.8rem]"
          />
          <div className="bg-[#aa9868]/30 w-full flex items-center">
            <button
              type="submit"
              className="w-fit text-black border py-2 px-4 rounded-md text-[0.8rem]
                     focus:outline-none hover:bg-[#a18322]/30"
            >
              Submit
            </button>
          </div>
        </form>
      )}
      <Footer />
    </Container>
  );
};

export default CatalogueRequest;

import logo from "../assets/images/logo/logo.png";
const Footer = () => {
  return (
    <footer className="bg-white dark:bg-gray-900 w-full border">
      <div className="mx-auto w-full max-w-screen-xl p-4 py-6 lg:py-8">
        <div className="md:flex md:justify-between">
          <div className="mb-6 md:mb-0">
            <a href="/" className="flex items-center">
              <img src={logo} className="w-[13rem]" alt="Fineweb" />
            </a>
          </div>
          <div className="grid grid-cols-2 gap-8 sm:gap-6 sm:grid-cols-3 text-start max-w-md">
            {/* Quick Links */}
            <div>
              <h2 className="mb-2 text-sm font-bold text-[#aa9868] uppercase">
                Quick Links
              </h2>
              <ul className="text-gray-500 text-[0.75rem]">
                <li className="my-1">
                  <a href="/" className="hover:underline">
                    Home
                  </a>
                </li>
                <li className="my-1">
                  <a href="/products" className="hover:underline">
                    Products
                  </a>
                </li>
                <li className="my-1">
                  <a href="/about-us" className="hover:underline">
                    About Us
                  </a>
                </li>
                <li className="my-1">
                  <a href="/contact" className="hover:underline">
                    Contact Us
                  </a>
                </li>
                <li className="my-1">
                  <a href="/sample" className="hover:underline">
                    Sample Request
                  </a>
                </li>
                <li className="my-1">
                  <a href="/offer" className="hover:underline">
                    Offer Request
                  </a>
                </li>
                <li className="my-1">
                  <a href="/catalogue" className="hover:underline">
                    Catalogue Request
                  </a>
                </li>
                <li className="my-1">
                  <a href="/webbing" className="hover:underline">
                    What is Webbing
                  </a>
                </li>
              </ul>
            </div>
            {/* Find Us */}
            <div>
              <h2 className="mb-2 text-sm font-bold text-[#aa9868] uppercase">
                Find Us
              </h2>
              <ul className="text-gray-500 text-[0.75rem]">
                <li className="my-1">+92-423-6815279</li>
                <li className="my-1">+92-316-0400050</li>
                <li className="my-1">info@fineweb.org</li>
                <li className="my-1">
                  2 km Yadgar Road, Qauid-e-Azam interchange, GT road, Lahore,
                  53400, Pakistan.
                </li>
              </ul>
            </div>
            {/* Request a quote */}
            <div>
              <h2 className="mb-2 text-sm font-bold text-[#aa9868] uppercase">
                Other
              </h2>
              <ul className="text-gray-500 text-[0.75rem]">
                <li className="my-1">
                  <a href="/contact" className="hover:underline">
                    Request a quote
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <hr className="my-6 border-[#aa9868] sm:mx-auto lg:my-8" />
        <div className="sm:flex sm:items-center sm:justify-between">
          <span className="text-sm text-[#aa9868] sm:text-center ">
            © 2024{" "}
            <a href="/" className="hover:underline">
              FineWeb™
            </a>
            . All Rights Reserved.
          </span>
          {/* <div className="flex mt-4 sm:justify-center sm:mt-0">
            <a
              href="#"
              className="text-gray-500 hover:text-gray-900 dark:hover:text-white"
            >
              <svg
                className="w-4 h-4 text-[#aa9868]"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="currentColor"
                viewBox="0 0 8 19"
              >
                <path
                  fillRule="evenodd"
                  d="M6.135 3H8V0H6.135a4.147 4.147 0 0 0-4.142 4.142V6H0v3h2v9.938h3V9h2.021l.592-3H5V3.591A.6.6 0 0 1 5.592 3h.543Z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="sr-only">Facebook page</span>
            </a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
const ProductCard = (props) => {
  const [show, setShow] = useState(false);
  const [show1, setShow1] = useState(false);
  const toggle = () => setShow(!show);
  const toggle1 = () => setShow1(!show1);

  // For PRODUCTS Page
  if (props.main) {
    return (
      <div className="w-80 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl">
        <div>
          <img
            src={props.productImg}
            alt={props.name}
            className="h-80 w-80 object-contain rounded-t-xl bg-[#aa9868]/30"
          />
          <div className="px-4 py-3 w-80">
            <p className="text-lg font-bold text-black truncate block capitalize">
              {props.name}
            </p>
            {props.sizes && (
              <p className="text-[0.8rem] text-gray-400 truncate block capitalize">
                Sizes: {props.sizes}
              </p>
            )}
            {props.showDetails ? (
              <p
                className="text-[0.8rem] text-[#aa9868] select-none 
              capitalize hover:underline cursor-pointer"
                onClick={toggle}
              >
                View Product Details
              </p>
            ) : (
              <p
                className="text-[0.8rem] text-[#aa9868] select-none 
              capitalize hover:underline cursor-pointer"
                onClick={toggle1}
              >
                View Product Details
              </p>
            )}
          </div>
        </div>
        <Modal
          scrollable
          centered
          size="xl"
          toggle={toggle}
          isOpen={show}
          className="p-0"
        >
          <ModalBody className="grid lg:grid-cols-2 p-0">
            {/* Col 1 */}
            <div className="h-full w-full flex flex-col">
              <div className="relative w-full h-full bg-[#aa9868]/30 flex justify-center items-center p-2">
                <img
                  src={props.productImg}
                  alt={props.name}
                  className="h-80 object-contain"
                />
                <div
                  className="absolute top-0 left-0 p-2 cursor-pointer text-sm text-black"
                  onClick={toggle}
                >
                  Close
                </div>
              </div>
              <div className="px-4 py-3 w-80">
                <p className="text-lg font-bold text-black truncate block capitalize">
                  {props.name}
                </p>
                {props.sizes && (
                  <p className="text-[0.8rem] text-gray-400 truncate block capitalize">
                    Sizes: {props.sizes}
                  </p>
                )}
              </div>
            </div>
            {/* Cols 2 */}
            <div className="grid grid-cols-1 gap-3 border-y">
              {props.name === "Sports Webbing" ? (
                <>
                  {props.colorRange.length > 0 && (
                    <div className="w-full flex flex-col py-1 justify-center items-center">
                      <p className="text-lg font-bold text-black truncate block capitalize">
                        Color
                      </p>
                      {props.colorRange.map((item, key) => (
                        <img
                          key={key}
                          src={item}
                          alt={props.name}
                          className="max-h-80 object-stretch flex justify-center items-center w-full"
                        />
                      ))}
                    </div>
                  )}
                  {props.quality.length > 0 && (
                    <div className="w-full flex flex-col py-1 justify-center items-center">
                      <p className="text-lg font-bold text-black truncate block capitalize">
                        Design Range
                      </p>
                      {props.quality.map((item, key) => (
                        <img
                          key={key}
                          src={item}
                          alt={props.name}
                          className="max-h-80 object-contain flex justify-center items-center w-full"
                        />
                      ))}
                    </div>
                  )}
                </>
              ) : (
                <>
                  {props.quality.length > 0 && (
                    <div className="w-full flex flex-col py-1 justify-center items-center">
                      <p className="text-lg font-bold text-black truncate block capitalize">
                        Quality
                      </p>
                      {props.quality.map((item, key) => (
                        <img
                          key={key}
                          src={item}
                          alt={props.name}
                          className="max-h-80 object-contain flex justify-center items-center w-full"
                        />
                      ))}
                    </div>
                  )}

                  {props.colorRange.length > 0 && (
                    <div className="w-full flex flex-col py-1 justify-center items-center">
                      <p className="text-lg font-bold text-black truncate block capitalize">
                        Color or Design Range
                      </p>
                      {props.colorRange.map((item, key) => (
                        <img
                          key={key}
                          src={item}
                          alt={props.name}
                          className="max-h-80 object-stretch flex justify-center items-center w-full"
                        />
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          </ModalBody>
        </Modal>

        <Modal
          scrollable
          centered
          size="md"
          toggle={toggle1}
          isOpen={show1}
          className="p-0"
        >
          <ModalBody className="p-0">
            <div className="h-full w-full flex flex-col">
              <div className="relative w-full h-full bg-[#aa9868]/30 flex justify-center items-center p-2">
                <img
                  src={props.productImg}
                  alt={props.name}
                  className="h-80 object-contain"
                />
                <div
                  className="absolute top-0 left-0 p-2 cursor-pointer text-sm text-black"
                  onClick={toggle1}
                >
                  Close
                </div>
              </div>
              <div className="px-4 py-3 w-80">
                <p className="text-lg font-bold text-black truncate block capitalize">
                  {props.name}
                </p>
                {props.sizes && (
                  <p className="text-[0.8rem] text-gray-400 truncate block capitalize">
                    Sizes: {props.sizes}
                  </p>
                )}
                {props.custom && (
                  <p className="text-[0.8rem] text-gray-400 truncate block capitalize">
                    {props.custom}
                  </p>
                )}
              </div>
            </div>
          </ModalBody>
        </Modal>
      </div>
    );
  }

  // For Home Page
  return (
    <a
      className="w-60 bg-white shadow-md rounded-xl duration-500 hover:scale-105 hover:shadow-xl"
      href={`/products/${props.name}`}
    >
      <div>
        <img
          src={props.productImg}
          alt={props.name}
          className="h-40 w-60 object-contain rounded-t-xl bg-[#aa9868]/30"
        />
        <div className="px-4 py-3 w-60">
          <p className="text-lg font-bold text-black truncate block capitalize">
            {props.name}
          </p>
          {props.sizes && (
            <p className="text-[0.8rem] text-gray-400 truncate block capitalize">
              Sizes: {props.sizes}
            </p>
          )}
        </div>
      </div>
    </a>
  );
};

export default ProductCard;

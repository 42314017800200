import React, { useState } from "react";
import { Container } from "reactstrap";
import Footer from "../../components/Footer";
import Spinner from "../../components/Spinner";
import emailjs from "@emailjs/browser";

const OfferRequest = () => {
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    productCategory: "",
    productDescription: "",
    widthReceive: "",
    amount: 0,
    companyName: "",
    name: "",
    email: "",
    contact: "",
    message: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoader(true);
    emailjs
      .send(
        "service_mca44pn",
        "template_bfd9zxl",
        {
          type: "Offer Request Form",
          productCategory: formData.productCategory,
          productDescription: formData.productDescription,
          widthReceive: formData.widthReceive,
          amount: formData.amount,
          companyName: formData.companyName,
          name: formData.name,
          street: "N/A",
          house: "N/A",
          houseSuffix: "N/A",
          zipCode: "N/A",
          city: "N/A",
          country: "N/A",
          email: formData.email,
          contact: formData.contact,
          message: formData.message,
        },
        "riT4aMzR3aTtGzRuq"
      )
      .then(
        (result) => {
          console.log("Email sent successfully:", result.text);
        },
        (error) => {
          console.error("Email sending failed:", error.text);
        }
      )
      .finally(() => {
        setLoader(false);
        setFormData({
          productCategory: "",
          productDescription: "",
          widthReceive: "",
          amount: 0,
          companyName: "",
          name: "",
          email: "",
          contact: "",
          message: "",
        });
      });
  };
  return (
    <Container
      fluid
      className="px-1 pt-3 mb-0 mx-0 h-fit md:min-h-screen lg:min-h-screen w-full justify-center items-center flex flex-col mt-[4.5rem]"
    >
      <section className="w-full flex flex-col justify-center items-center p-3 min-h-[15rem] bg-[#aa9868]/40 mb-4">
        <h1 className="max-w-2xl mt-2 mb-4 p-2 text-md text-justify">
          <span className="font-bold">REQUEST OFFERS</span>
          <br className="mb-3" />
          Are you interested in one of our products and do you want to receive a
          price offer? Please go ahead and fill in our offer request form. We
          are happy to make a personal offer that suits your industry. You can
          fill out the form below and the quotation will be sent at short notice
          by our enthusiastic sales team.
          <br className="mb-3" />
          If you have any additional questions, we’re committed to providing you
          with the best advice. Feel free to include your questions in the
          ‘Additional Information’ section, and we’ll be sure to address them
          promptly.
        </h1>
      </section>

      {loader ? (
        <div className="h-screen flex justify-center items-center">
          <Spinner />
        </div>
      ) : (
        <form
          className="max-w-lg min-w-[80%] lg:min-w-[45%] border p-4 mb-4"
          onSubmit={handleSubmit}
        >
          <div className="grid grid-cols-2 gap-2 mb-2 w-full">
            <div>
              <label
                htmlFor="productCategory"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Product Category
              </label>
              <select
                id="productCategory"
                name="productCategory"
                onChange={handleChange}
                className="mt-1 p-1.5 w-full border text-[0.8rem]"
              >
                <option>Select - Option</option>
                <option>Nawar Webbing</option>
                <option>Fine Special Webbing</option>
                <option>Multi Webbing</option>
                <option>Printed Webbing</option>
                <option>Fancy Webbing</option>
                <option>Sports Webbing</option>
                <option>Maghzi Webbing</option>
                <option>Special Webbing</option>
                <option>H.L.W Webbing</option>
              </select>
            </div>
            <div>
              <label
                htmlFor="productDescription"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Product Description
              </label>
              <input
                type="text"
                id="productDescription"
                name="productDescription"
                onChange={handleChange}
                placeholder="For example: Qualities, designs, colors.."
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
          </div>
          {/* ----------------- */}
          <label
            htmlFor="widthReceive"
            className="block text-[0.7rem] font-medium text-gray-600"
          >
            Which widths (in inches) would you like a quote for?
          </label>
          <input
            type="text"
            id="widthReceive"
            name="widthReceive"
            onChange={handleChange}
            placeholder="For example: ½”, ¾”, 1”, 1 ¼”, 1 ½”, 2”"
            required
            className="mt-1 p-1 w-full border text-[0.8rem]"
          />
          {/* ----------------- */}

          <label
            htmlFor="amount"
            className="block text-[0.7rem] font-medium text-gray-600 mt-2"
          >
            Amount of rolls or pieces to test{" "}
          </label>
          <input
            type="number"
            id="amount"
            name="amount"
            placeholder="Enter amount"
            onChange={handleChange}
            required
            className="mt-1 p-1 w-full border text-[0.8rem]"
          />
          {/* ----------------- */}
          <div className="grid grid-cols-2 gap-2 my-2 w-full">
            <div>
              <label
                htmlFor="companyName"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Company Name
              </label>
              <input
                type="text"
                id="companyName"
                name="companyName"
                onChange={handleChange}
                placeholder="Your Company Name"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="name"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Name
              </label>
              <input
                type="text"
                id="name"
                name="name"
                onChange={handleChange}
                placeholder="Your Full Name"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
          </div>
          {/* ----------------- */}
          <div className="grid grid-cols-2 gap-2 mb-2 w-full">
            <div>
              <label
                htmlFor="email"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Email
              </label>
              <input
                type="email"
                id="email"
                name="email"
                onChange={handleChange}
                placeholder="Your Email"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
            <div>
              <label
                htmlFor="contact"
                className="block text-[0.7rem] font-medium text-gray-600"
              >
                Contact
              </label>
              <input
                type="tel"
                id="contact"
                name="contact"
                onChange={handleChange}
                placeholder="Your Contact Number"
                required
                className="mt-1 p-1 w-full border text-[0.8rem]"
              />
            </div>
          </div>
          {/* ----------------- */}
          <label
            htmlFor="message"
            className="block text-[0.7rem] font-medium text-gray-600 mt-2"
          >
            Additional Information
          </label>
          <textarea
            type="text"
            id="message"
            name="message"
            placeholder="Your message here..."
            onChange={handleChange}
            required
            className="mt-1 p-1 w-full border text-[0.8rem]"
          />
          <div className="bg-[#aa9868]/30 w-full flex items-center">
            <button
              type="submit"
              className="w-fit text-black border py-2 px-4 rounded-md text-[0.8rem]
                     focus:outline-none hover:bg-[#a18322]/30"
            >
              Submit
            </button>
          </div>
        </form>
      )}
      <Footer />
    </Container>
  );
};

export default OfferRequest;

import fancyWeb from "../images/products/Fancy webbing.png";
import fineSpecial from "../images/products/Fine Special webbing.png";
import hlw from "../images/products/H.L.W webbing.png";
import maghzi from "../images/products/Maghzi webbing.png";
import multiWeb from "../images/products/Multi webbing .png";
import nawarWeb from "../images/products/Nawar Webbing .png";
import printedWeb from "../images/products/Printed webbing .png";
import specialWeb from "../images/products/Special webbing.png";
import sportsWeb from "../images/products/Sports webbing .png";
import nawar_maghzi from "../images/products/Color Range NMW.png";
import nawar_quality from "../images/products/Nawar Webbing Quality.png";
import maghzi_quality from "../images/products/Maghzi Webbing Quality .png";
import fancy_range from "../images/products/Color Range Fancy Webbing.png";
import multi_quality from "../images/products/Multi Colour Webbing Designs.png";
import sports_range from "../images/products/Sports Webbing Colour range.png";
import sports_quality from "../images/products/Sports Webbing Designs available 1.png";
import sports_quality1 from "../images/products/Sports Webbing Designs avaliable 2.png";

export const PRODUCTS = [
  {
    name: "Nawar Webbing",
    productImg: nawarWeb,
    sizes: "½”, ¾”, 1”, 1 ¼”, 1 ½”, 2”",
    showDetails: true,
    colorRange: [nawar_maghzi],
    quality: [nawar_quality],
    custom: "",
  },

  {
    name: "Fine Special Webbing",
    productImg: fineSpecial,
    sizes: "1”, 1¼”, 1½”",
    showDetails: false,
    colorRange: [],
    quality: [],
    custom: "Colors: Black, Brown, Katha",
  },
  {
    name: "Multi Webbing",
    productImg: multiWeb,
    sizes: "¾”, 1”, 1¼”",
    showDetails: true,
    colorRange: [multi_quality],
    quality: [],
    custom: "",
  },
  {
    name: "Printed Webbing",
    productImg: printedWeb,
    sizes: "1”, 1¼”, 1½”",
    showDetails: false,
    colorRange: [],
    quality: [],
    custom: "Note: It can also be customised on order basis.",
  },
  {
    name: "Fancy Webbing",
    productImg: fancyWeb,
    sizes: "¾”, 1”, 1¼”, 1½”",
    showDetails: true,
    colorRange: [fancy_range],
    quality: [],
    custom: "",
  },
  {
    name: "Sports Webbing",
    productImg: sportsWeb,
    sizes: "½”, ¾”, 1”",
    showDetails: true,
    colorRange: [sports_range],
    quality: [sports_quality, sports_quality1],
    custom: "",
  },
  {
    name: "Maghzi Webbing",
    productImg: maghzi,
    sizes: "1”, ¾”",
    showDetails: true,
    colorRange: [nawar_maghzi],
    quality: [maghzi_quality],
    custom: "",
  },
  {
    name: "Special Webbing",
    productImg: specialWeb,
    sizes: "All Available",
    showDetails: false,
    colorRange: [],
    quality: [],
    custom: "Color: Black",
  },
  {
    name: "H.L.W Webbing",
    productImg: hlw,
    sizes: "1½”, 2”",
    showDetails: false,
    colorRange: [],
    quality: [],
    custom: "Color: Black",
  },
];
